import { EmpresasView, MainView, ContadorView } from "../views";
import { MainLayout } from "../layouts";

const CurrentRoutes = [
  {
    component: EmpresasView,
    exact: true,
    layout: MainLayout,
    path: "/empresas",
    title: "Listagem de empresas para o contador",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de empresas para o contador",
  },
  {
    component: MainView,
    exact: true,
    layout: MainLayout,
    path: "/dashboard",
    title: "Dashboard",
    podeExibirNaBusca: true,
    descricao: "Dashboards e informações importantes sobre a empresa",
  },
  {
    component: ContadorView,
    exact: false,
    layout: MainLayout,
    path: "/contador/:id",
    title: "Contador",
    podeExibirNaBusca: false,
    descricao: "Vizualizar informações do contador",
  },
];

export default CurrentRoutes;
