import { Toolbar as ToolbarMui } from "@mui/material";

const Toolbar = ({ children, classes, component, disableGutters, sx }) => {
  return (
    <ToolbarMui
      classes={classes}
      component={component}
      disableGutters={disableGutters}
      sx={sx}
    >
      {children}
    </ToolbarMui>
  );
};

export default Toolbar;
