import { useState, createContext, useContext } from "react";
import { formatDate } from "../utils";
const ImportContext = createContext({});

export const ImportContextProvider = ({ children }) => {
  const [pedidoDto, setPedidoDto] = useState({
    itens: [],
  });
  const [nfSaidaDto, setNfSaidaDto] = useState({
    dataEmissao: formatDate.toSend(new Date()),
    nfSaidaItens: [],
    nfSaidaPagamentos: [],
    documentoImportado: false,
  });
  const [nfEntradaDto, setNfEntradaDto] = useState({
    nfEntradaItens: [],
    nfEntradaTributos: {},
  });
  const [contaReceberDto, setContaReceberDto] = useState({
    pago: false,
    dataLancamento: formatDate.toSend(new Date()),
  });
  const [empresaId, setEmpresaId] = useState({});
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openCadastros, setOpenCadastros] = useState(false);
  const [openCompras, setOpenCompras] = useState(false);
  const [openVendas, setOpenVendas] = useState(false);
  const [openFinanceiro, setOpenFinanceiro] = useState(false);
  const [openRelatorios, setOpenRelatorios] = useState(false);
  const [openContador, setOpenContador] = useState(false);

  const initialCollapses = () => {
    setOpenCadastros(false);
    setOpenCompras(false);
    setOpenVendas(false);
    setOpenFinanceiro(false);
    setOpenRelatorios(false);
  };

  return (
    <ImportContext.Provider
      value={{
        pedidoDto,
        setPedidoDto,
        nfSaidaDto,
        setNfSaidaDto,
        nfEntradaDto,
        setNfEntradaDto,
        contaReceberDto,
        setContaReceberDto,
        empresaId,
        setEmpresaId,
        openCollapse,
        setOpenCollapse,
        openCadastros,
        setOpenCadastros,
        openCompras,
        setOpenCompras,
        openVendas,
        setOpenVendas,
        openFinanceiro,
        setOpenFinanceiro,
        openRelatorios,
        setOpenRelatorios,
        openContador,
        setOpenContador,
        initialCollapses,
      }}
    >
      {children}
    </ImportContext.Provider>
  );
};

export const useImportContext = () => useContext(ImportContext);
