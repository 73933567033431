import SiafLiteApiRepository from "../../external/siafLiteApi";

class EmpresaService {
  constructor() {
    this.urlBase = "/painel-controle/empresas";
  }

  async getEmpresasContador() {
    return new SiafLiteApiRepository(`${this.urlBase}/lista/contador`).get();
  }
}

export default EmpresaService;
