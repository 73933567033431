import StorageHelper from "../helpers/StorageHelper";
import { history } from "./index";

class SessionService {
  constructor() {
    this.storageHelper = new StorageHelper();
  }

  getUsuario() {
    const dadosSession = this.storageHelper.getLocal("dados");
    const dados = JSON.parse(dadosSession);
    return dados.usuario;
  }

  getEmpresa() {
    const dadosSession = this.storageHelper.getLocal("dados");
    const dados = JSON.parse(dadosSession);
    return dados.empresa;
  }

  getListaEmpresas() {
    const dadosSession = this.storageHelper.getLocal("empresas");
    return JSON.parse(dadosSession);
  }

  getEmpresaAndUsuario() {
    const dadosSession = this.storageHelper.getLocal("dados");
    return JSON.parse(dadosSession);
  }

  getPermisoes() {
    const dadosSession = this.storageHelper.getLocal("dados");
    if (dadosSession) {
      const dados = JSON.parse(dadosSession);
      return dados.usuario.perfilUsuario.permisoes;
    }
    return [];
  }

  deslogar() {
    this.storageHelper.removeLocal("token");
    this.storageHelper.removeLocal("rotinas");
    this.storageHelper.removeLocal("theme");
    this.storageHelper.removeLocal("dados");
    this.storageHelper.removeLocal("empresas");
    this.storageHelper.removeSession("notificacao");
    this.storageHelper.removeLocal("bancoCaixaIdPdv");
    history.push("/login");
  }
}

export default SessionService;
