/* eslint-disable no-unused-vars */
import { Minimal } from "../../layouts";
import { DataGrid } from "../../components";
import { SessionService, history } from "../../services";
import { formatCnpj, formatCpf } from "../../utils";
import { useEffect, useState } from "react";
import { useImportContext } from "../../contexts/import.context";
import _ from "lodash";
import { FiltroCustom } from "./components";

const EmpresasView = () => {
  const { setEmpresaId } = useImportContext();
  const [listEmpresas, setListEmpresas] = useState([]);
  const sessionService = new SessionService();
  const columns = [
    {
      field: "id",
      headerName: "Codigo",
      flex: 50,
      filtrar: true,
    },
    {
      field: "razaoSocial",
      headerName: "Nome/RazaoSocial",
      flex: 230,
      filtrar: true,
    },
    {
      field: "cnpjCpf",
      headerName: "CNPJ / CPF",
      flex: 230,
      valueGetter: (params) =>
        params.row.naturezaSocial === "fisica"
          ? formatCpf(params.value)
          : formatCnpj(params.value),
      filtrar: true,
    },
  ];

  useEffect(() => {
    buscarListaEmpresas();
  }, []);

  function buscarListaEmpresas(filtro) {
    const listaEmpresas = sessionService.getListaEmpresas();
    filtro
      ? setListEmpresas(filtrarLista(listaEmpresas, filtro))
      : setListEmpresas(listaEmpresas);
  }

  function filtrarLista(lista, filtro) {
    const filtroFormatado = _.deburr(filtro.trim().toLowerCase());
    return lista.filter((item) => {
      return Object.values(item).some((valor) => {
        if (valor !== undefined && valor !== null) {
          const valorFormatado = _.deburr(
            valor.toString().trim().toLowerCase()
          );
          return valorFormatado.includes(filtroFormatado);
        }
        return false;
      });
    });
  }

  return (
    <Minimal title="Empresas">
      <DataGrid
        rows={listEmpresas}
        columns={columns}
        paginationMode={"server"}
        rowCount={listEmpresas.length}
        imposto={true}
        onClickViewImposto={(empresaId) => {
          setEmpresaId(empresaId);
          history.push("/dashboard");
        }}
        FiltroCustom={<FiltroCustom buscaListaEmpresas={buscarListaEmpresas} />}
        hideFiltroAvancado
      />
    </Minimal>
  );
};

export default EmpresasView;
