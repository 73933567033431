/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { AppBar } from "@mui/material";
import { Toolbar, Badge, IconButton, Typography } from "../../components";
import "./topbar.style.css";
import authActions from "../../store/modules/notificacao/actions";
import { history, SessionService } from "../../services";
import { formatCnpj, formatCpf } from "../../utils";

const Topbar = ({ handleDrawerOpen, open, quantidadeMensagemNaoLida }) => {
  const menuId = "primary-search-account-menu";
  const [empresa, setEmpresa] = useState(null);
  const [usuario, setUsuario] = useState(null);
  const [anchorElConfig, setAnchorElConfig] = useState(null);
  const [anchorSearchEl, setAnchorSearchEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const sessionService = new SessionService();
  const dadosUsuarioAndEmpresa = sessionService.getEmpresaAndUsuario();

  useEffect(() => {
    buscarDadosEmpresa(dadosUsuarioAndEmpresa);
  }, []);

  const buscarDadosEmpresa = (dadosUsuarioAndEmpresa) => {
    setEmpresa(dadosUsuarioAndEmpresa.empresa);
    setUsuario(dadosUsuarioAndEmpresa.usuario);
  };

  const handleProfileConfigOpen = (event) => {
    setAnchorElConfig(event.currentTarget);
  };

  const handleSearchMenuOpen = (event) => {
    event.preventDefault();
    setAnchorSearchEl(event.currentTarget);
  };

  const handleDrawerTranstion = async () => {
    await handleDrawerOpen();
  };

  return (
    <>
      <AppBar
        className={
          clsx("appBar") && clsx("topDrwPaper", !open && "topDrwPaperClose")
        }
      >
        <Toolbar>
          <div className="containerTopBar">
            <IconButton
              edge="start"
              color="primary"
              aria-label="open drawer"
              onClick={handleDrawerTranstion}
              style={{
                borderRadius: 16,
                margin: "0 10px",
                padding: " 0 15px",
              }}
            >
              {open ? (
                <i
                  className="ph-fill ph-dots-three-outline-vertical"
                  style={{ fontSize: 22 }}
                ></i>
              ) : (
                <i className="ph-bold ph-list" style={{ fontSize: 22 }}></i>
              )}
            </IconButton>
          </div>
          <div className="sectionDesktop">
            {usuario && (
              <IconButton
                className="me-1"
                style={{
                  padding: 10,
                  borderRadius: 12,
                  marginLeft: 25,
                  boxShadow: "0px 0px 56px rgba(0, 0, 0, 0.08)",
                }}
                color="inherit"
                onClick={() => history.push(`/contador/${usuario.id}`)}
              >
                <i
                  className="ph-fill ph-user-circle"
                  style={{ fontSize: 25, marginRight: 10 }}
                ></i>
                {!open || usuario.nome?.length <= 40 ? (
                  <div className="d-flex flex-column align-items-start">
                    <Typography
                      style={{
                        color: "#494C62",
                        textTransform: "uppercase",
                      }}
                      variant="caption"
                    >
                      {usuario.nome}
                    </Typography>
                  </div>
                ) : null}
              </IconButton>
            )}
            {/*<Badge
              badgeContent={quantidadeMensagemNaoLida}
              color="error"
              variant="dot"
              overlap="circular"
            ></Badge>   
            <IconButton
              onClick={() => history.push(`/meu-usuario`)}
              color="inherit"
              style={{
                padding: 7,
                color: "#494C62",
                borderRadius: 30,
                backgroundColor: "#E5E5E5",
                marginLeft: 25,
              }}
            >
              <i
                className="ph-fill ph-user-circle"
                style={{ fontSize: 25 }}
              ></i>
            </IconButton>
            {empresa && (
              <IconButton
                className="me-1"
                style={{
                  padding: 10,
                  borderRadius: 12,
                  marginLeft: 25,
                  boxShadow: "0px 0px 56px rgba(0, 0, 0, 0.08)",
                }}
                color="inherit"
              >
                <i
                  className="ph-fill ph-buildings"
                  style={{ fontSize: 25, marginRight: !open ? 10 : 0 }}
                ></i>
                {!open || empresa.fantasia?.length <= 40 ? (
                  <div className="d-flex flex-column align-items-start">
                    <Typography
                      style={{
                        color: `${"#494C62"}`,
                        textTransform: "uppercase",
                      }}
                      variant="caption"
                    >
                      {empresa.fantasia}
                    </Typography>
                    <Typography
                      style={{
                        color: "#494C62",
                        textTransform: "uppercase",
                      }}
                      variant="caption"
                    >
                      {empresa.cnpjCpf?.length == 11
                        ? formatCpf(empresa.cnpjCpf)
                        : formatCnpj(empresa.cnpjCpf)}
                    </Typography>
                  </div>
                ) : null}
              </IconButton>
            )}
             <IconButton
              color="inherit"
              onClick={handleSwitchTheme}
              style={{
                padding: 7,
                borderRadius: 30,
                backgroundColor: themeMode === "light" ? "#E5E5E5" : "#0C111A",
                marginLeft: 25,
              }}
            >
              {themeMode === "light" ? (
                <i className="ph-fill ph-sun-dim" style={{ fontSize: 23 }}></i>
              ) : (
                <i className="ph-fill ph-moon" style={{ fontSize: 23 }}></i>
              )}
            </IconButton> */}
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  mensagens: state.notificacao.mensagens,
  quantidadeMensagemNaoLida: state.notificacao.quantidadeMensagemNaoLida,
});

const mapDispatchToProps = (dispatch) => ({
  setMensagens: (mensagens) => dispatch(authActions.setMensagens(mensagens)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Topbar));
