import { Router } from "react-router-dom";
import { history } from "./services";
import { ThemeContextProvider } from "./contexts/theme.context";
import "react-perfect-scrollbar/dist/css/styles.css";
import Routes from "./routes";
import { connect } from "react-redux";
import authActions from "./store/modules/notificacao/actions";

const App = () => {
  return (
    <ThemeContextProvider>
      <Router history={history}>
        <Routes />
      </Router>
    </ThemeContextProvider>
  );
};

const mapStateToProps = (state) => ({
  mensagens: state.notificacao.mensagens,
});

const mapDispatchToProps = (dispatch) => ({
  setMensagens: (mensagens) => dispatch(authActions.setMensagens(mensagens)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
