import { BotaoVoltar, Card, UsuarioLog } from "..";

const MenuCustom = ({ codigo, ButtonMenu, userLog, isFullscreenDialog }) => {
  return (
    <Card className="px-3 py-3 mb-2 d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center justify-content-end">
        {!isFullscreenDialog && <BotaoVoltar />}
        <div>{ButtonMenu}</div>
        {userLog && (
          <UsuarioLog
            createDate={userLog?.createdAt}
            editDate={userLog?.updatedAt}
            userCreatedLog={userLog?.userCreatedLog}
            userEditedLog={userLog?.userEditedLog}
          />
        )}
      </div>
      {codigo && <span>{codigo}</span>}
    </Card>
  );
};

export default MenuCustom;
