/* eslint-disable no-unused-vars */
import { Route, Redirect } from "react-router-dom";
import StorageHelper from "../helpers/StorageHelper";

const storageHelper = new StorageHelper();

const PrivateRoutes = ({
  component: Component,
  layout: Layout,
  title,
  ...rest
}) => {
  const token = storageHelper.getLocal("token");

return (
    <Route
      {...rest}
      render={(matchProps) =>
        token ? (
          <Layout title={title}>
            <Component {...matchProps} />
          </Layout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoutes;
