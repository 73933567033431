import { DashboardCard, DashboardChart } from ".";

const ResumoNfe = ({ relatorioNfSaidaNfe }) => {
  return (
    <>
      <DashboardCard
        title="NF-E"
        ActionIcon={
          <i
            className="ph-bold ph-currency-dollar"
            style={{ color: "#494C62", fontSize: 22 }}
          ></i>
        }
      >
        <DashboardChart
          data={relatorioNfSaidaNfe}
          options={{
            chart: {
              type: "column",
            },
            plotOptions: {
              column: {
                dataLabels: {
                  style: {
                    color: "#666",
                    fontWeight: 400,
                    border: "none",
                  },
                  enabled: true,
                  formatter: function () {
                    if (this.y > 0) {
                      return this.y;
                    }
                  },
                },
              },
            },
            yAxis: {
              allowDecimals: false,
              stackLabels: {
                style: {
                  color: "#fff",
                  fontWeight: 600,
                  border: "none",
                },
                enabled: true,
              },
            },
            tooltip: {
              formatter: function () {
                const { x, y, series } = this;
                return `
                <div class="chart-tip">
                  <span class="title">${x}</span>
                  <div class='content'>
                    <span style="color: ${series.color}; font-size: 20px;">●</span>
                    <b>${series.name}: </b> ${y}
                  </div>  
                </div>`;
              },
            },
          }}
        />
      </DashboardCard>
    </>
  );
};

export default ResumoNfe;
