import SiafLiteApiRepository from "../../external/siafLiteApi";

class ContadorService {
  constructor() {
    this.urlBase = "/painel-controle/contador";
  }

  getById(id) {
    return new SiafLiteApiRepository(this.urlBase).getById(id);
  }
}

export default ContadorService;
