import { useState, useEffect } from "react";
import { responseErros } from "../../utils";
import { ContadorService } from "../../services";
import { FlexLayout } from "../../layouts";
import { CardContent, Grid, TextField } from "../../components";
import { InputErros, value } from "../../helpers";

const ContadorView = ({ match }) => {
  const { id } = match.params;
  const [loading, setLoading] = useState(false);
  const [contador, setContador] = useState({});
  const contadorService = new ContadorService();
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  useEffect(() => {
    buscarContador();
  }, []);

  const buscarContador = async () => {
    if (!id) return;
    setLoading(true);
    const result = await contadorService.getById(id);
    if (!result.isAxiosError) {
      if (result.data) {
        setContador(result.data);
      }
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const handleUserInputChange = (e) => {
    if (e.target.name == "senha" || e.target.name == "confirmarSenha") {
      return setContador((prevState) => ({
        ...prevState,
        [e.target.name]: String(e.target.value).trim(),
      }));
    }
    setContador((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <FlexLayout id={id} loading={loading}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="nome"
              name="nome"
              label="Nome"
              variant="outlined"
              value={value.text(contador?.nome)}
              onChange={handleUserInputChange}
              fullWidth
              disabled
              error={inputErros.get("nome")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="cnpj"
              name="cnpj"
              label="Cnpj"
              variant="outlined"
              value={value.text(contador?.cnpj)}
              onChange={handleUserInputChange}
              fullWidth
              disabled
              error={inputErros.get("cnpj")}
            />
          </Grid>
        </Grid>
      </CardContent>
    </FlexLayout>
  );
};

export default ContadorView;
