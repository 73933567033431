import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Loading,
  MenuCustom,
  BotaoVoltar,
  CardActions,
} from "../../components";
import { useThemeContext } from "../../contexts/theme.context";

const Minimal = ({
  children,
  loading,
  ButtonMenu,
  title,
  cardAction,
  menuCustom,
}) => {
  const { themeMode } = useThemeContext();

  return (
    <Container>
      {menuCustom && (
        <MenuCustom className="d-flex justify-content-end">
          <BotaoVoltar />
          <div>{ButtonMenu}</div>
        </MenuCustom>
      )}
      <Card
        className="p-2"
        style={{
          backgroundColor: themeMode === "light" ? "#fff" : "#171C26",
        }}
      >
        <CardHeader title={title} />
        <CardContent>{children}</CardContent>
        {cardAction && <CardActions>{cardAction}</CardActions>}
      </Card>
      <Loading loading={loading} />
    </Container>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
};

export default Minimal;
