import createAxiosConfig from "../../config/createAxiosConfig";
import store from "../../config/store";
import encrypt from "../../utils/encrypt";

class LoginService {
  constructor() {
    this.urlBase = "/auth";
    this.axiosConfig = createAxiosConfig(store.baseUrl);
  }

  async logar(emailSenha, tokenRecaptcha) {
    const basicAuth = encrypt(emailSenha);
    const body = { basicAuth, tokenRecaptcha };
    return this.axiosConfig
      .post(`${this.urlBase}/login-contador`, body)
      .then((reponse) => reponse)
      .catch((erro) => erro);
  }

  async gerarCodigoRecuperacaoSenha(email) {
    const body = {
      email,
    };
    return this.axiosConfig
      .post(`${this.urlBase}/recuperar-senha/gerar-codigo`, body)
      .then((reponse) => reponse)
      .catch((erro) => erro);
  }

  async validarCodigoRecuperacaoSenha(email, codigo) {
    const body = {
      email,
      codigo,
    };
    return this.axiosConfig
      .post(`${this.urlBase}/recuperar-senha/validar-codigo`, body)
      .then((reponse) => reponse)
      .catch((erro) => erro);
  }
}

export default LoginService;
