import SiafLiteApiRepository from "../../external/siafLiteApi";

class NfSaidaService {
  constructor() {
    this.urlBase = "/faturamento/nf-saida";
  }

  getVendasPorMes(filtros) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/estatistica/resumo-saidas-mes`
    ).patch(filtros);
  }

  getQuantidadeVendasPorMes(filtros) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/estatistica/resumo-quantidade-saidas-mes`
    ).patch(filtros);
  }

  getRelatorioDocumentoContador(empresaId, body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${empresaId}/relatorios/documentos-contador`
    ).patch(body);
  }
}

export default NfSaidaService;
