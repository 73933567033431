/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Container,
  DatePicker,
  Grid,
  TextField,
} from "../../components";
import "./main.view.scss";
import store from "../../config/store";
import { formatDate, responseErros } from "../../utils";
import { DialogBaixarXml, ResumoNfce, ResumoNfe } from "./components";
import { NfSaidaService, SessionService } from "../../services";
import { useImportContext } from "../../contexts/import.context";
const sessionService = new SessionService();

const Main = () => {
  const { empresaId } = useImportContext();
  const nfSaidaService = new NfSaidaService();
  const [contadorDashboard, setContadorDashboard] = useState({
    date: formatDate.toSend(new Date()),
  });
  const [empresa, setEmpresa] = useState({});
  const [listEmpresas, setListEmpresas] = useState([]);
  const [relatorioNfSaidaNfe, setRelatorioNfSaidaNfe] = useState({
    series: [],
    categories: [],
  });
  const [relatorioNfSaidaNfce, setRelatorioNfSaidaNfce] = useState({
    series: [],
    categories: [],
  });
  const [openDialog, setOpenDialog] = useState(false);

  const onChangeDate = async (date, name) => {
    if (!date) {
      date = "Invalid Date";
    }
    const data = date != "Invalid Date" ? formatDate.toSend(date) : "";
    setContadorDashboard((prevState) => ({ ...prevState, [name]: data }));
    await buscarRelatorios(data, empresa?.id);
  };

  useEffect(() => {
    const empresas = sessionService.getListaEmpresas();
    if (empresas?.length && empresaId) {
      const empresaTemp = empresas.find((empresa) => empresa.id == empresaId);
      setListEmpresas(empresas);
      setEmpresa(empresaTemp);
      buscarRelatorios(contadorDashboard.date, empresaTemp?.id);
    }
  }, []);

  async function buscarRelatorios(data = new Date().toISOString(), empresaId) {
    if (empresaId) {
      const result = await nfSaidaService.getQuantidadeVendasPorMes({
        dataReferencia: data,
        modeloDocumento: true,
        empresaId,
      });
      if (!result.isAxiosError) {
        gerarDadosNfeNfce(result.data);
      } else {
        responseErros(result);
      }
    }
  }

  function gerarDadosNfeNfce(data) {
    const dadosNfe = data.length
      ? data.filter((nfe) => nfe.modelo === "55")
      : [];
    const dadosNfce = data.length
      ? data.filter((nfe) => nfe.modelo === "65")
      : [];
    setRelatorio(setRelatorioNfSaidaNfe, dadosNfe);
    setRelatorio(setRelatorioNfSaidaNfce, dadosNfce);
  }

  function setRelatorio(set, dados) {
    set({
      categories: dados.map((item) => `${item.dia}/${item.mes}`),
      series: [
        {
          name: "Autorizadas",
          color: "#4FBB53",
          type: "column",
          borderColor: null,
          data: dados.map((nfce) => nfce.totalEfetivadas),
        },
        {
          name: "Canceladas",
          type: "column",
          data: dados.map((nfce) => nfce.totalCanceladas),
          borderColor: null,
          color: "#e44a42",
        },
      ],
    });
  }

  return (
    <>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={2}>
            <DatePicker
              required
              shrink
              label="Mês/Ano"
              placeholder="Selecione Mês e Ano"
              format="MM/yyyy"
              views={["month", "year"]}
              value={contadorDashboard?.date}
              onChange={(date) => onChangeDate(date, "date")}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="empresaId"
              name="empresaId"
              noOptionsText="Sem opções"
              options={listEmpresas}
              autoHighlight
              getOptionLabel={({ razaoSocial }) => razaoSocial ?? ""}
              renderInput={(params) => (
                <TextField {...params} label="Empresa" variant="outlined" />
              )}
              onChange={(_, newValue) => {
                setEmpresa(newValue);
                buscarRelatorios(contadorDashboard?.date, newValue.id);
              }}
              value={empresa}
            />
          </Grid>
          <Grid item xs={4} className="d-flex justify-content-end">
            <Button
              color="primary"
              variant="contained"
              className="px-4 mx-1"
              onClick={() => setOpenDialog(true)}
            >
              <i
                className="ph-fill ph-cloud-arrow-down"
                style={{ fontSize: 22, marginRight: 10, color: "white" }}
              ></i>
              Baixar Xml
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="mt-3">
          <Grid item xs={6}>
            <ResumoNfe relatorioNfSaidaNfe={relatorioNfSaidaNfe} />
          </Grid>
          <Grid item xs={6}>
            <ResumoNfce relatorioNfSaidaNfce={relatorioNfSaidaNfce} />
          </Grid>
        </Grid>
        <footer className="versao">
          Versão Siaf Lite Contador - {store?.version}
        </footer>
      </Container>
      <DialogBaixarXml
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        contadorDashboard={contadorDashboard}
        setContadorDashboard={setContadorDashboard}
        empresa={empresa}
      />
    </>
  );
};

export default Main;
