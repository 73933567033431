import { useState, createContext, useContext, useEffect } from "react";
import { StorageHelper } from "../helpers";

const ThemeContext = createContext({});

export const ThemeContextProvider = ({ children }) => {
  const [themeMode, setThemeMode] = useState("light");
  const storageHelper = new StorageHelper();

  useEffect(() => {
    const savedTheme = storageHelper.getLocal("theme");
    if (savedTheme) setThemeMode(savedTheme);
  }, []);

  const handleSwitchTheme = () => {
    if (themeMode == "light") {
      setThemeMode("dark");
      storageHelper.setLocal("theme", "dark");
    } else {
      setThemeMode("light");
      storageHelper.setLocal("theme", "light");
    }
  };

  return (
    <ThemeContext.Provider value={{ themeMode, handleSwitchTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
