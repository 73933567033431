/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbar,
  ptBR,
  GridCellEditStopReasons,
} from "@mui/x-data-grid";
import { Grid, IconButton, Button, Tooltip } from "../../components";
import shortid from "shortid";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Pesquisar, FiltroAvancadoDialog } from "./components";
import { PermisoesHelper } from "../../helpers";

const filtros = {
  orderBy: "updatedAt",
  order: "desc",
  page: "1",
};

const DataGridCustom = (props) => {
  const theme = createTheme(ptBR);
  const [tipoFiltro, setTipoFiltro] = useState("simples");
  const [page, setPage] = useState(0);
  const [busca, setbusca] = useState("");
  const [queryBusca, setQueryBusca] = useState({});
  const [colunas, setColunas] = useState([]);
  const [colunaFiltroSimples, setColunaFiltroSimples] = useState(null);
  const [openFiltroAvancadoDialog, setOpenFiltroAvancadoDialog] =
    useState(false);
  const [filtrosAvancados, setFiltrosAvancados] = useState({});
  const [permisao, setPermisao] = useState({});
  const [modulo, setModulo] = useState(null);
  const permisoesHelper = new PermisoesHelper();

  useEffect(() => {
    buscarPermisoes();
  }, []);

  useEffect(() => {
    atualizaColunas(props.columns);
  }, [props.columns]);

  const buscarPermisoes = () => {
    const url = window.location.pathname.split("/");
    const modulo = url.length != 4 ? url[2] : "";
    setModulo(modulo);
    setPermisao(permisoesHelper.getPermisoesModulo(modulo));
  };

  const atualizaColunas = (columns) => {
    const colunasComId = columns.map((column) => {
      if (column.filtrar) {
        setColunaFiltroSimples(column);
      }
      return {
        ...column,
        id: shortid.generate(),
      };
    });
    if (!props.disableAcoes) {
      colunasComId.push({
        id: shortid.generate(),
        field: "acoes",
        headerName: "Ações",
        sortable: false,
        flex: 140,
        disableClickEventBubbling: true,
        hideable: false,
        renderCell: (params) => {
          return (
            <div>
              {props.imposto && (
                <IconButton
                  onClick={() => {
                    props.onClickViewImposto(params.id);
                  }}
                >
                  <Tooltip title="Visualizar Imposto" arrow placement="right">
                    <i className="ph-fill ph-eye" style={{ fontSize: 18 }}></i>
                  </Tooltip>
                </IconButton>
              )}
              {(permisao?.visualizar || props.editShow) && (
                <IconButton
                  onClick={() => {
                    props.onClickEdit(params.id);
                  }}
                >
                  {params.row.status === "CANCELADO" || modulo == "nf-saida" ? (
                    <Tooltip title="Visualizar" arrow placement="right">
                      <i
                        className="ph-fill ph-eye"
                        style={{ fontSize: 18 }}
                      ></i>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Editar" arrow placement="right">
                      <i
                        className="ph-fill ph-pencil"
                        style={{ fontSize: 18 }}
                      ></i>
                    </Tooltip>
                  )}
                </IconButton>
              )}
              {(permisao?.excluir || props.deleteShow) && (
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    props.onClickDelete(params.id);
                  }}
                >
                  {modulo == "orcamentos" || modulo == "pedidos" ? (
                    <Tooltip title="Cancelar" arrow placement="right">
                      <i
                        className="ph-fill ph-x-circle"
                        style={{ fontSize: 18 }}
                      ></i>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Excluir" arrow placement="right">
                      <i
                        className="ph-fill ph-trash"
                        style={{ fontSize: 18 }}
                      ></i>
                    </Tooltip>
                  )}
                </IconButton>
              )}
            </div>
          );
        },
      });
    }
    setColunas(colunasComId);
  };

  const handleSortModelChange = (params) => {
    if (props.paginationMode == "server") {
      if (
        params.length &&
        (filtros.orderBy != params[0].field || filtros.order != params[0].sort)
      ) {
        filtros.orderBy = params[0].field == "codigo" ? "id" : params[0].field;
        filtros.order = params[0].sort;
        const retorno = { tipoFiltro };
        if (tipoFiltro == "simples") {
          retorno.filtros = { ...filtros, ...queryBusca };
        } else {
          retorno.filtros = { ...filtros, ...filtrosAvancados };
        }
        props.sendServer(retorno);
      }
    }
  };

  const handlePageChange = (params) => {
    setPage(params.page);
    if (props.paginationMode == "server") {
      filtros.page = (params.page + 1).toString();
      const retorno = { tipoFiltro };
      if (tipoFiltro == "simples") {
        retorno.filtros = { ...filtros, ...queryBusca };
      } else {
        retorno.filtros = { ...filtros, ...filtrosAvancados };
      }
      props.sendServer(retorno);
    }
  };

  const handlePesquisaAvancada = async () => {
    setPage(0);
    setOpenFiltroAvancadoDialog(false);
    setTipoFiltro("avancada");
    filtros.page = "1";
    const retorno = {
      tipoFiltro: "avancada",
      filtros: {
        ...filtros,
        ...filtrosAvancados,
      },
    };
    props.sendServer(retorno);
  };

  return (
    <>
      <Grid container spacing={1} direction="row" alignItems="center">
        {props.paginationMode == "server" && !props.noFilter && (
          <>
            {!props.FiltroCustom ? (
              <Grid item xs={4}>
                <Pesquisar
                  setPage={setPage}
                  filtros={filtros}
                  setQueryBusca={setQueryBusca}
                  colunas={props.columns}
                  sendServer={props.sendServer}
                  busca={busca}
                  setbusca={setbusca}
                  setTipoFiltro={setTipoFiltro}
                  tipoFiltro={tipoFiltro}
                  colunaFiltroSimples={colunaFiltroSimples}
                />
              </Grid>
            ) : (
              <Grid item xs={6}>
                {props.FiltroCustom}
              </Grid>
            )}
            {!props.hideFiltroAvancado && (
              <Grid item xs={6}>
                <Button
                  onClick={() => setOpenFiltroAvancadoDialog(true)}
                  size="large"
                  color="inherit"
                  variant="outlined"
                >
                  <span className="text-capitalize">Filtro</span>
                  <i
                    className="ph-bold ph-funnel-simple"
                    style={{ color: "#494C62", fontSize: 18, paddingLeft: 10 }}
                  ></i>
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <ThemeProvider theme={theme}>
            <DataGrid
              className={props.className}
              rowSelectionModel={props.selectionModel}
              onRowSelectionModelChange={props.onRowSelectionModelChange}
              checkboxSelection={props.checkboxSelection}
              pageSizeOptions={[10]}
              columnVisibilityModel={props.columnVisibilityModel}
              rows={props.rows}
              columns={colunas}
              autoHeight
              onSortModelChange={(params) => handleSortModelChange(params)}
              sortingMode={
                props.paginationMode ? props.paginationMode : "client"
              }
              disableColumnMenu
              pageSize={props.pageSize}
              page={page}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: props.nonPaginated ? 100 : 25,
                  },
                },
                sorting: {
                  sortModel: props.sortModel,
                },
              }}
              rowCount={props.rowCount}
              onPaginationModelChange={(params) => handlePageChange(params)}
              paginationMode={
                props.paginationMode ? props.paginationMode : "client"
              }
              disableRowSelectionOnClick={props.disableRowSelectionOnClick}
              onRowDoubleClick={(params) =>
                !props.disableAcoes &&
                (permisao?.visualizar || props.editShow) &&
                props?.onClickEdit(params.id) ||
                props?.onClickViewImposto(params.id)
              }
              onCellKeyDown={props.onCellKeyDown}
              onCellEditStop={(params, event) => {
                if (params.reason === GridCellEditStopReasons.cellFocusOut) {
                  event.defaultMuiPrevented = true;
                }
              }}
              slots={{
                toolbar: GridToolbar,
              }}
              disableColumnFilter={true}
              disableDensitySelector={true}
            />
          </ThemeProvider>
        </Grid>
      </Grid>
      {!props.noFilter && (
        <FiltroAvancadoDialog
          openFiltroAvancadoDialog={openFiltroAvancadoDialog}
          setOpenFiltroAvancadoDialog={setOpenFiltroAvancadoDialog}
          filtrosAvancados={filtrosAvancados}
          setFiltrosAvancados={setFiltrosAvancados}
          handlePesquisaAvancada={handlePesquisaAvancada}
          FormFiltroAvancado={props.FormFiltroAvancado}
        />
      )}
    </>
  );
};

export default DataGridCustom;
