import { ListItemButton as ListItemButtonMui } from "@mui/material";
import { forwardRef } from "react";

const ListItemButton = forwardRef(
  (
    {
      children,
      autoFocus,
      disabled,
      dense,
      disableGutters,
      divider,
      focusVisibleClassName,
      selected,
      component,
      className,
      style,
      href,
      onClick,
    },
    ref
  ) => {
    return (
      <ListItemButtonMui
        ref={ref}
        autoFocus={autoFocus}
        disabled={disabled}
        dense={dense}
        disableGutters={disableGutters}
        divider={divider}
        focusVisibleClassName={focusVisibleClassName}
        selected={selected}
        component={component}
        className={className}
        style={style}
        href={href}
        onClick={onClick}
      >
        {children}
      </ListItemButtonMui>
    );
  }
);

export default ListItemButton;
