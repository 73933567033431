import { Card as CardMui } from "@mui/material";

const Card = ({ children, classes, raised, sx, className }) => {
  return (
    <CardMui
      classes={classes}
      raised={raised}
      sx={{
        ...sx,
        boxShadow: "0px 0px 56px rgba(0, 0, 0, 0.1) !important",
      }}
      className={className}
    >
      {children}
    </CardMui>
  );
};

export default Card;
