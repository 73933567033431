import { useRef } from "react";
import { Grid, TextField } from "../../../components";

const FiltroCustom = ({ buscaListaEmpresas }) => {
  const inputPesquisar = useRef();
  const onSearchChangePesquisar = (event) => {
    if (
      event.key === "Enter" &&
      inputPesquisar.current === document.activeElement
    ) {
      event.target?.value?.length > 2
        ? buscaListaEmpresas(event.target.value)
        : buscaListaEmpresas();
      inputPesquisar.current.value = "";
    }
  };

  return (
    <div className="mb-3 mt-1 align-items-center justify-content-start">
      <Grid container xs={12} spacing={2}>
        <Grid item xs={8}>
          <TextField
            label="Pesquisar"
            variant="outlined"
            margin="normal"
            onKeyDown={(e) => onSearchChangePesquisar(e)}
            inputRef={inputPesquisar}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default FiltroCustom;
