import { SessionService } from "../services";
const sessionService = new SessionService();

class PemisaoHelper {
  permisoes = null;

  constructor() {
    this.permisoes = sessionService.getPermisoes();
  }

  temPermisaoMenu(moduloNome) {
    let permisaoBusca = null;
    if (Array.isArray(moduloNome)) {
      for (const nome of moduloNome) {
        permisaoBusca = this.permisoes.find(
          (permisao) => permisao.modulo == nome && permisao.visualizar
        );
        if (permisaoBusca) {
          break;
        }
      }
    } else {
      permisaoBusca = this.permisoes.find(
        (permisao) => permisao.modulo == moduloNome && permisao.visualizar
      );
    }
    if (permisaoBusca) {
      return true;
    } else {
      return false;
    }
  }

  getPermisoesModulo(moduloNome) {
    return this.permisoes.find((permisao) => permisao.modulo == moduloNome);
  }
}

export default PemisaoHelper;
