import { CardActions as CardActionsMui } from "@mui/material";

const CardActions = ({ children, classes, disableSpacing, sx, className }) => {
  return (
    <CardActionsMui
      classes={classes}
      disableSpacing={disableSpacing ? true : false}
      sx={{
        ...sx,
        display: "flex",
        padding: "16px !important",
        margin: "auto 11px 4px auto !important",
      }}
      className={className}
    >
      {children}
    </CardActionsMui>
  );
};

export default CardActions;
