import { Button } from "../../components";
import { formatDate, voltar } from "../../utils";
import "./botao-voltar.css";
import { useImportContext } from "../../contexts/import.context";

const BotaoVoltar = () => {
  const {
    setNfSaidaDto,
    setNfEntradaDto,
    setPedidoDto,
    setContaReceberDto,
    initialCollapses,
  } = useImportContext();

  const voltarInitial = () => {
    setPedidoDto({
      itens: [],
    });
    setNfSaidaDto({
      dataEmissao: formatDate.toSend(new Date()),
      nfSaidaItens: [],
      nfSaidaPagamentos: [],
      documentoImportado: false,
    });
    setNfEntradaDto({
      nfEntradaItens: [],
      nfEntradaTributos: {},
    });
    setContaReceberDto({
      pago: false,
      dataLancamento: formatDate.toSend(new Date()),
    });
    initialCollapses();
    voltar();
  };

  return (
    <Button variant="text" color="padrao" onClick={voltarInitial}>
      <i
        className="ph-fill ph-arrow-left"
        style={{ color: "#494C62", fontSize: 23 }}
      ></i>
    </Button>
  );
};
export default BotaoVoltar;
